import React from "react"
import achievementStyles from './a4_achievement.module.css'
import LayoutA4 from "../../components/layout-a4"
import  LayoutA4Main from '../../components/layout-a4-main'

export default class A4Achievement extends React.Component {
  render() {
    const color="#693b83"
    return(
      <LayoutA4 >
        <LayoutA4Main>
        <div className={achievementStyles.achievement}>
          <div className={achievementStyles.scanCodeTitle} style={{color:color}}>扫码查看</div>
          <div className={achievementStyles.code}><img alt="hello" src={require("../../images/a2/race_code.png")}></img></div>
          <div className={achievementStyles.scanCodeDesc}>打开微信扫一扫</div>
          <div  className={achievementStyles.toWx}>打开微信查看成绩</div>
          <div className={achievementStyles.rankingContainer}>
            {/*<div className="ranking-icon"><img  alt="hello" src={require(this.state.width<480?'../images/ranking-phone.png':"../images/rankings.png")}/></div>*/}
            <div className={achievementStyles.achievementRanking}>
              {/*{ranking}*/}
              <img alt="hello" src={require('../../images/a4/ranking.png')}/>
            </div>
          </div>
        </div>
        </LayoutA4Main>
      </LayoutA4>
    )
  }
}